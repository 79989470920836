<template>
  <div>
    <select-ajax-user
        :model.sync="selectedUser"
        :multiple="true"
        :is-for-email="true"
        :taggable="true"
        :is-required="true"
        name="dev"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import vSelect                  from 'vue-select'
import useAPI                   from '../../utils/useAPI'
import SelectAjaxUser           from '../../components/input/custom/selectAjax/SelectAjaxUser.vue'
import Select2                  from '../../components/input/Select2.vue'

export default {
  components: { Select2, SelectAjaxUser, vSelect },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedUser = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedUser, val => {
      console.log(val)
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      selectedUser,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>